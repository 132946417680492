.card {
  background-color: aquamarine;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 250px;
  transition: all 0.6s;
}
.card:hover {
  transform: scale(1.049);
  cursor: pointer;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
}

.container-card {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h3.title-card,
h6.subtitle-card {
  margin: 0;
  padding: 0;
  text-align: center;
}

h3.title-card {
  font-size: 22px;
  margin-top: 18px;
  white-space: nowrap;
}
h6.subtitle-card {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .container-card {
    width: 100%;
  }
}
