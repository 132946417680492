@font-face {
  font-family: "UltraViolent BB Regular";
  font-style: normal;
  font-weight: normal;
  src: local("UltraViolent BB Regular"),
    url("fonts/ultraviolentbb_reg.woff") format("woff");
}

@font-face {
  font-family: "UltraViolent BB Italic";
  font-style: normal;
  font-weight: normal;
  src: local("UltraViolent BB Italic"),
    url("fonts/ultraviolentbb_ital.woff") format("woff");
}

body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 0;
  margin: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(
    to left,
    rgba(7, 27, 82, 1) 0%,
    rgba(0, 128, 128, 1) 100%
  );
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-family: "UltraViolent BB Regular";
  color: #0ccac4;
  font-size: 38px;
}

input {
  padding: 8px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 30px;
  outline: #020202 2px solid;
  z-index: 1000;
}
input:focus {
  outline: #0ccac4 3px solid;
}
input.fixed {
  position: fixed;
  top: 14px;
}
